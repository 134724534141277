<template>
  <v-container>
    <div v-if="apiError">Ошибка загрузки данных</div>
    <div v-if="dataLoaded">

      <div class="employe-card">
        <h1>Редактирование информации о сотруднике</h1>

        <div class="form-wrap">
          <v-text-field outlined label="Фамилия" v-model.trim="employee.surname"></v-text-field>
          <v-text-field outlined label="Имя" v-model.trim="employee.name"></v-text-field>
          <v-text-field outlined label="Отчество" v-model.trim="employee.middleName"></v-text-field>

          <DateField label="Дата рождения" :value="employee.birthday" @input="employee.birthday = $event"
            min-date="1900-01-01" :max-date="todayMaxDate">
          </DateField>

          <h2>Контакты</h2>

          <v-text-field outlined label="электронная почта" v-model.trim="employee.email"></v-text-field>
          <v-text-field outlined label="телефон" v-model.trim="employee.phoneNumber"></v-text-field>

          <h2>Образование и квалификация</h2>

          <v-textarea outlined label="Образование" v-model.trim="employee.education"></v-textarea>

          <v-text-field filled outlined label="специальность" v-model.trim="employee.specialty"></v-text-field>
          <v-text-field filled outlined label="ученая степень" v-model.trim="employee.degree"></v-text-field>
          <v-text-field filled outlined label="область квалификации" v-model.trim="employee.qualification"></v-text-field>

          <v-checkbox label="Штатный" v-model="employee.inState"></v-checkbox>
          <template v-if="!employee.inState">
            <v-text-field filled outlined label="Привлекать через"></v-text-field>
          </template>
          <template v-if="employee.inState">
            <h2>Текущее место работы</h2>
            <section v-for="(job, jobIndex) in employee.jobs" :key="'jobsit_' + jobIndex" style="padding-bottom: 10px">
              <v-select label="название организации" filled outlined :items="ourOrgs" return-object
                item-text="fullName" v-model="job.organization"></v-select>
              <v-text-field filled outlined label="должность по договору" v-model="job.position"></v-text-field>

              <DateField label="Дата трудоустройства" :value="employee.birthday" min-date="1998-01-01"
                max-date="2029-12-31" v-model="job.employmentDate"></DateField>

              <v-text-field filled outlined label="номер и дата договора" v-model.trim="job.contractNumAndDate">
              </v-text-field>

              <v-select label="тип занятости" outlined clearable :items="jobTypeDict" item-value="code"
                item-text="title" v-model="job.jobType">
              </v-select>
              <a href="#" class="add" @click.prevent="deleteJob(jobIndex)">- Удалить место работы</a>
              <hr>
            </section>
            <a href="#" class="add" @click.prevent="addJob()">+ добавить место работы</a>
          </template>

          <h2>Дополнительная информация</h2>

          <v-textarea filled outlined v-model.trim="employee.extraInfo"></v-textarea>

          <h2>Недостающие документы</h2>
          <v-textarea filled outlined v-model.trim="employee.missingDocs"></v-textarea>

          <div v-if="$user.roles.includes('ROLE_ADMIN')">
            <h2>Роли</h2>
            <v-checkbox label="Партнер" v-model="employee.isRolePartner"></v-checkbox>
            <v-checkbox label="Эксперт" v-model="employee.isRoleOffer"></v-checkbox>
            <v-checkbox label="Бэк-офис" v-model="employee.isRoleFormal"></v-checkbox>
            <v-checkbox label="Участник проектов - не партнер" v-model="employee.isNonPartnerParticipant"></v-checkbox>


          </div>

          <div style="margin-top:20px">
            <v-btn outlined @click="employee.id == null ? $router.push('/employees/') : $router.push('/employee/' + employee.id + '/info')">Отменить</v-btn>
            <v-btn @click="save()">Сохранить</v-btn>
          </div>


        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import api from '../../modules/api'
import DateField from "@/components/elements/DateField";
import { jobTypeDict } from '../../modules/NSI';
import dayjs from 'dayjs';

export default {
  name: 'EditEmployeeComponent',
  components: { DateField },
  props: {},
  data() {
    return {
      apiError: false,
      dataLoaded: false,
      jobTypeDict: jobTypeDict,
      ourOrgs: [],
      employee: {},
    };
  },
  computed: {
    todayMaxDate: function() {
      return dayjs(new Date()).format('YYYY-MM-DD')
    }
  },
  methods: {
    async save() {
      if(this.employee.inState){
        delete this.employee.attractThrough
      }
      else{
        delete this.employee.jobs
      }
      console.log(this.employee)
      let req = this.employee.id == null ? await api.postJson('/supmain/experts', this.employee) :
                                      await api.putJson('/supmain/experts', this.employee)
      console.log(req)
      if(req.ok) {
        this.$router.push('/employee/' + req.payload.id + '/info')
      }
    },

    addJob() {
      if (this.employee.jobs == null)
        this.employee.jobs = []
      this.employee.jobs.push({})
      this.$forceUpdate()
    },

    deleteJob(index) {
      this.employee.jobs.splice(index, 1)
      this.$forceUpdate()
    }
  },
  async beforeMount() {
    let ourOrgsReq = api.get('supmain/legalentities?search=isOurOrg:true')
    let id = this.$route.params.id
    if (id == null) {
      ourOrgsReq = await ourOrgsReq
      this.ourOrgs = ourOrgsReq.payload
      this.dataLoaded = true
      return
    }
    else {
      let req1 = await api.get("/supmain/experts/" + id)
      ourOrgsReq = await ourOrgsReq
      if (req1.ok && ourOrgsReq.ok) {
        this.ourOrgs = ourOrgsReq.payload
        this.employee = req1.payload
        this.dataLoaded = true
      }
    }
  }
}
</script>
